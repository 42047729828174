import React from 'react';
import { Spin } from 'antd';

import type { SpinProps } from 'antd/lib/spin';

import './index.less';

const Loading: React.FC<SpinProps> = (props) => {
  const { spinning, ...otherProps } = props;

  if (!spinning) return null;

  return (
    <div className="fx__layout-loading">
      <Spin spinning size="large" {...otherProps} />
    </div>
  );
};

export default Loading;
