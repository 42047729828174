import { useMemo, useRef } from 'react';

export function useWujieProps(defaultProps: Record<string, any>, deep?: any[]) {
  const eventMapRef = useRef(new Map<string, { effects?: Set<(val: any) => void>; value?: any }>());

  return useMemo(() => {
    const eventMap = eventMapRef.current;

    return {
      ...defaultProps,
      /**
       * 观察 key 数据的变化
       * @param key 要观察的数据
       * @param effect 回调函数
       * @param immediate 创建时立即执行一遍回调
       */
      watch(key: string, effect: (val: any) => void, immediate = false) {
        const found = eventMap.get(key);
        const effects = found?.effects || new Set();
        effects.add(effect);

        eventMap.set(key, { ...found, effects });

        if (immediate) {
          effect(found?.value);
        }
      },
      /**
       * 释放观察行为
       * @param key
       * @param effect
       */
      revokeWatch(key: string, effect: (val: any) => void) {
        const effects = eventMap.get(key)?.effects;
        effects?.delete(effect);
      },
      /**
       * 设置 key 的数据
       * @param key
       * @param value
       */
      set(key: string, value: any) {
        const found = eventMap.get(key);

        eventMap.set(key, { ...found, value });
        // 执行回调
        found?.effects?.forEach((effect) => effect(value));
      },
      /**
       * 获取 key 的数据
       * @param key
       * @returns
       */
      get(key: string) {
        return eventMap.get(key)?.value;
      },
    };
  }, deep || []);
}
