import dayjs from 'dayjs';
import OSS from 'ali-oss';
import SparkMD5 from 'spark-md5';
import { message } from 'antd';
import HttpClient from './httpClient';

interface IUploadSTS {
  host: string;
  region: string;
  accessKeyId: string;
  accessKeySecret: string;
  stsToken: string;
  bucket: string;
  expiration: number;
  folder: string;
}

interface IUploadSuccessParams {
  file: File;
  name: string;
  url: string;
  size: number;
}

interface IUploadSrcOptions {
  url: string;
  lastModified: number;
  name: string;
  type: string;
  size: number;
}

type IUploadFileOptions =
  | IUploadSrcOptions
  | {
      file: File;
      lastModified?: number;
      name?: string;
      type?: string;
      size?: number;
    };

export type IUploadOptions = IUploadFileOptions & {
  beforeUpload?: (file: File) => boolean | string;
  cancel?: (cancel: () => Promise<any>) => void;
  onProgress?: (progress: number) => void;
  onSuccess?: (res: IUploadSuccessParams) => void;
  onError?: (error: Error) => void;
};

/** 单个文件分片同时上传并发数 */
const PARALLEL = 5;

/** 每个切片的字节数 */
const PART_SIZE = 5 * 1024 * 1024;

function getShortMD5(file: File) {
  return new Promise<string>((resolve, reject) => {
    const spark = new SparkMD5.ArrayBuffer();

    const fileReader = new FileReader();

    // 获取第一片的 md5
    fileReader.readAsArrayBuffer(file.slice(0, PART_SIZE));

    fileReader.onload = (e) => {
      spark.append(e.target?.result as any);
      const res = spark.end();
      resolve(res);
    };

    fileReader.onerror = (e) => {
      reject(e);
    };
  });
}

function getFileExtension(filename: string) {
  const match = filename.match(/\.([a-zA-Z0-9]+)$/);
  return match ? match[1] : null;
}

export interface FileItem {
  id: number;
  /** 文件名 */
  name: string;
  /** 文件大小 */
  size: number;
  /** 上传进度 */
  progress: number;
  /** 状态 */
  status: 'pending' | 'process' | 'success' | 'error';
  msg?: string;
  successTime?: string;
  /** 取消上传 */
  cancel?: () => void;
  /** 重新上传 */
  reset?: () => void;
  /** 暂停上传 */
  stop?: () => void;
  /** 恢复上传 */
  resume?: () => void;
}

interface Task {
  id: number;
  abortCheckpoint: any;
  options: IUploadOptions;
  getFile: () => Promise<File>;
  cancel: (client?: OSS) => void;
}

interface Upload {
  (...options: IUploadOptions[]): Promise<void>;
}

interface RunTask {
  (): void;
  (retryTask: Task, endProgress: number): void;
}

class UploadQueue {
  private fetchSTS: Promise<IUploadSTS> | null = null;

  private taskId = 1;
  private concurrent: number;

  private filesMap = new Map<number, FileItem>();

  private pendingQueue = new Set<Task>();
  private queue = new Set<Task>();

  private listeners = new Set<(tasks: FileItem[]) => void>();

  constructor(maxConcurrent: number) {
    this.concurrent = maxConcurrent || 5;
    window.onbeforeunload = () => {
      if (this.pendingQueue.size || this.queue.size) {
        return '放弃当前未上传内容而关闭页面？';
      }
    };
  }

  /** 添加上传文件 */
  upload: Upload = async (...options) => {
    try {
      options.forEach((item) => {
        const id = this.taskId++;
        let name: string;
        let size: number;

        if ('file' in item) {
          name = item.file.name;
          size = item.file.size;
        } else {
          name = item.name;
          size = item.size;
        }

        const task: Task = {
          id,
          abortCheckpoint: undefined,
          options: item,
          async getFile() {
            if ('file' in item) {
              return item.file;
            }
            return new File(
              [await fetch(item.url).then((res) => res.blob())],
              item.name || '未命名文件',
              {
                type: item.type,
                lastModified: item.lastModified,
              },
            );
          },
          cancel: (client?: OSS) => {
            this.filesMap.delete(id);
            task.abortCheckpoint &&
              client?.abortMultipartUpload(
                task.abortCheckpoint.name,
                task.abortCheckpoint.uploadId,
              );

            this.emitChange();

            this.pendingQueue.delete(task);
            this.queue.delete(task);

            this.run();
          },
        };

        const fileItem: FileItem = {
          id,
          name,
          size,
          progress: 0,
          status: 'pending',
          cancel: () => {
            task.cancel();
          },
        };

        this.filesMap.set(id, fileItem);
        this.pendingQueue.add(task);
      });

      this.emitChange();

      for (let i = 0; i < this.concurrent; i++) {
        this.run();
      }
    } catch (error) {
      message.error('获取上传凭证失败，请重新登录');
    }
  };

  /** 清除缓存 */
  cleanAuth = () => {
    localStorage.removeItem('oss-auth');
    this.fetchSTS = null;
  };

  listen(change: (tasks: FileItem[]) => void) {
    const items = [...this.filesMap.values()];
    change(items);
    this.listeners.add(change);
  }

  /** 上传 */
  private run: RunTask = async (retryTask?: Task, endProgress?: number) => {
    if (this.queue.size >= this.concurrent && !retryTask) {
      return;
    }

    const [task] = retryTask ? [retryTask] : this.pendingQueue;

    if (!task) {
      return;
    }

    this.pendingQueue.delete(task);
    this.queue.add(task);

    const { id, getFile, options } = task;

    const file = await getFile();

    const [sts, shortMD5] = await Promise.all([this.getSTSAuth(), getShortMD5(file)]);

    const uploadId = `programme/file/${sts.folder}/${SparkMD5.hash(`${file.name}-${shortMD5}`)}${
      options.name && 'src' in options ? '' : `.${getFileExtension(file.name)}`
    }`;

    const data = {
      file,
      name: file.name,
      url: `https://${sts.host}/${uploadId}`,
      size: file.size,
    };

    let progress = endProgress || 0;

    this.emitChange(id, {
      status: 'process',
      progress,
    });

    try {
      if (options.beforeUpload) {
        const res = await options.beforeUpload(file);
        if (res === false || typeof res === 'string') {
          throw res || '上传失败';
        }
      }

      let exist = false;
      const client = new OSS({
        region: sts.region,
        accessKeyId: sts.accessKeyId,
        accessKeySecret: sts.accessKeySecret,
        stsToken: sts.stsToken,
        bucket: sts.bucket,
        timeout: 15 * 60 * 60,
      });

      const fileItem = this.filesMap.get(id);

      if (fileItem) {
        // 更新取消上传事件
        fileItem.cancel = () => {
          task.cancel(client);
        };
      }

      try {
        // 判断文件是否存在
        await client.head(uploadId);
        // 营造假进度过渡动画
        progress = Math.max(Math.random(), progress);
        options.onProgress?.(progress);
        this.emitChange(id, { status: 'process', progress });
        await new Promise((resolve) => setTimeout(resolve, 600));
        exist = true;
      } catch (error) {
        // 文件不存在
      }

      if (!exist) {
        await client.multipartUpload(uploadId, file, {
          checkpoint: task.abortCheckpoint,
          progress: (_progress, cpt, res) => {
            progress = Math.max(_progress, progress);
            task.abortCheckpoint = cpt;
            options.onProgress?.(progress);
            this.emitChange(id, { status: 'process', progress });
          },
          parallel: PARALLEL,
          partSize: PART_SIZE,
        });
      }

      // 成功回调
      this.emitChange(id, {
        status: 'success',
        progress: 1,
        successTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      });
      options.onSuccess?.(data);

      // 执行下一个任务
      this.queue.delete(task);
      this.run();
    } catch (error) {
      console.error(error);

      if (!retryTask) {
        // 更新上传凭证，重试一次
        this.cleanAuth();
        this.run(task, progress);
        return;
      }

      const msg = typeof error === 'string' ? error : '哎呀，一不小心出错了，重新上传试试吧。';

      // 失败回调
      this.emitChange(id, { status: 'error', msg });
      options.onError?.(error as any);

      // 执行下一个任务
      this.queue.delete(task);
      this.run();
    }
  };

  /** 更新 file list */
  private emitChange(uploadId?: number, options?: Partial<FileItem>) {
    if (!uploadId) {
      const items = [...this.filesMap.values()];
      this.listeners.forEach((exec) => exec(items));
      return;
    }

    const item = this.filesMap.get(uploadId);

    if (item && options) {
      Object.entries(options).forEach(([key, value]) => {
        Reflect.set(item, key, value);
      });
      const items = [...this.filesMap.values()];
      this.listeners.forEach((exec) => exec(items));
    }
  }

  /** 获取 STS 凭证 */
  private getSTSAuth: (tryCount?: number) => Promise<IUploadSTS> = async (tryCount = 3) => {
    try {
      const auth = JSON.parse(localStorage.getItem('oss-auth') || 'null') as IUploadSTS | null;

      if (auth?.expiration) {
        // 超过有效期重置
        if (dayjs(auth.expiration).diff(dayjs()) <= 0) {
          return auth;
        }

        this.cleanAuth();
      }

      if (this.fetchSTS) {
        return this.fetchSTS;
      }

      this.fetchSTS = HttpClient.post('/program/programme/file/sts-token').then(({ data }) => {
        const res: IUploadSTS = {
          host: data.host,
          region: data.region,
          bucket: data.bucket,
          folder: data.folder,
          accessKeyId: data.credential.accessKeyId,
          accessKeySecret: data.credential.accessKeySecret,
          stsToken: data.credential.securityToken,
          expiration: data.credential.expiration,
        };

        localStorage.setItem('oss-auth', JSON.stringify(res));

        return res;
      });

      return this.fetchSTS;
    } catch (err) {
      this.cleanAuth();

      if (tryCount > 0) {
        return this.getSTSAuth(tryCount - 1);
      }

      throw new Error('OSS Access Key：获取文件上传凭证失败');
    }
  };
}

export default UploadQueue;
