import React, { useEffect, useMemo, useState } from 'react';
import Iframe from 'react-iframe';
import Cookies from 'js-cookie';
import { TokenKey, getApplicationUrl } from '@/const/serviceEnv';
import { useLocation } from 'react-router-dom';
import { useTabNavigate } from '@/hooks/useTabNavigate';

interface WebviewProps {
  url?: string;
  needLGJtoken?: boolean;
  isSupplier?: boolean;
  width?: string;
  height?: string;
  needCenter?: boolean;
  parseQueryToMatch?: boolean;
}

const LiguanjiaUrl = getApplicationUrl('liguanjia');
const CardStoreUrl = getApplicationUrl('cardStore');
const SupplierUrl = getApplicationUrl('supplier');

interface WebviewType {
  name: string;
  path: string;
  props: WebviewProps;
}

const webviewList: WebviewType[] = [
  {
    name: '代发订单管理',
    path: '/webview/dropship/list',
    props: {
      url: `${LiguanjiaUrl}/dispatch/batchHistory`,
    },
  },
  {
    name: '创建代发订单',
    path: '/webview/dropship/create',
    props: {
      url: `${LiguanjiaUrl}/dispatch/batchOrder`,
    },
  },
  {
    name: '商品素材下载',
    path: '/webview/dropship/goods/download',
    props: {
      url: `${LiguanjiaUrl}/dispatch/productList`,
    },
  },
  {
    name: '下单成功通知',
    path: '/webview/dropship/order/notice',
    props: {
      url: `${LiguanjiaUrl}/dispatch/noticeMobile`,
    },
  },
  {
    name: '卡券管理',
    path: '/webview/plugin/coupon/list',
    props: {
      url: `${CardStoreUrl}/#/card-manager?isIframe=1&hideMenu=1&isFromWonderful=1`,
      needLGJtoken: true,
      width: '1200px',
      height: '1000px',
      needCenter: true,
    },
  },
  {
    name: '卡券推广',
    path: '/webview/plugin/coupon/generalization',
    props: {
      url: `${CardStoreUrl}/#/card-extension?isIframe=1&hideMenu=1&isFromWonderful=1`,
      needLGJtoken: true,
      width: '1200px',
      height: '1000px',
      needCenter: true,
    },
  },
  {
    name: '卡券统一兑换',
    path: '/webview/plugin/coupon/exchange',
    props: {
      url: `${CardStoreUrl}/#/card-exchange?isIframe=1&hideMenu=1&isFromWonderful=1`,
      needLGJtoken: true,
      width: '1200px',
      height: '1000px',
      needCenter: true,
    },
  },
  {
    name: '挑选供应商商品',
    path: '/webview/lgj/goods/audit',
    props: {
      url: `${LiguanjiaUrl}/product_pick/self_goods_list`,
    },
  },
  {
    name: '商城订单',
    path: '/webview/lgj/mall/order/list',
    props: {
      url: `${LiguanjiaUrl}/esOrder/index`,
    },
  },
  {
    name: '订单导出',
    path: '/webview/lgj/mall/order/export',
    props: {
      url: `${LiguanjiaUrl}/OrderExport/index_web`,
    },
  },
  {
    name: '项目开发订单',
    path: '/webview/lgj/service/order/development/list',
    props: {
      url: `${LiguanjiaUrl}/team_develop/lists`,
    },
  },
  {
    name: '退货地址',
    path: '/webview/lgj/mall/setting/return/address',
    props: {
      url: `${LiguanjiaUrl}/team/rights_set`,
    },
  },
  {
    name: '订单确认方式',
    path: '/webview/lgj/mall/setting/order/confirm',
    props: {
      url: `${LiguanjiaUrl}/team/pay_type`,
    },
  },
  {
    name: '订单异常预警',
    path: '/webview/lgj/mall/setting/order/warning',
    props: {
      url: `${LiguanjiaUrl}/team/order_warning`,
    },
  },
  {
    name: '订单挂起通知',
    path: '/webview/lgj/mall/setting/order/suspend/warning',
    props: {
      url: `${LiguanjiaUrl}/team/order_pending_warning`,
    },
  },
  {
    name: '订单每日简报',
    path: '/webview/lgj/mall/setting/order/daily/report',
    props: {
      url: `${LiguanjiaUrl}/team_message/sms_notification_setting`,
    },
  },
  {
    name: '我的账户',
    path: '/webview/lgj/settlement/account',
    props: {
      url: `${LiguanjiaUrl}/finance/index`,
    },
  },
  {
    name: '商品账户充值',
    path: '/webview/lgj/settlement/account/recharge',
    props: {
      url: `${LiguanjiaUrl}/finance/recharge`,
    },
  },
  {
    name: '服务账户充值',
    path: '/webview/lgj/settlement/account/rechargeNewService',
    props: {
      url: `${LiguanjiaUrl}/finance/rechargeNewService`,
    },
  },
  {
    name: '商品交易汇总',
    path: '/webview/lgj/settlement/dealings/summary',
    props: {
      url: `${LiguanjiaUrl}/finance/finance_summary`,
    },
  },
  {
    name: '商品交易明细',
    path: '/webview/lgj/settlement/dealings/list',
    props: {
      url: `${LiguanjiaUrl}/finance/finance_detail`,
    },
  },
  {
    name: '对账单',
    path: '/webview/lgj/settlement/bill',
    props: {
      url: `${LiguanjiaUrl}/finance/finance_reconciliation`,
    },
  },
  {
    name: '商品发票',
    path: '/webview/lgj/settlement/purchase/invoice',
    props: {
      url: `${LiguanjiaUrl}/invoice/index?type=product`,
    },
  },
  {
    name: '运费发票',
    path: '/webview/lgj/settlement/freight/invoice',
    props: {
      url: `${LiguanjiaUrl}/invoice/index?type=freight`,
    },
  },
  {
    name: '技术发票',
    path: '/webview/lgj/settlement/service/invoice',
    props: {
      url: `${LiguanjiaUrl}/invoice/index?type=service`,
    },
  },
  {
    name: '短信概览',
    path: '/webview/lgj/settlement/message/dashboard',
    props: {
      url: `${LiguanjiaUrl}/team_message/index`,
    },
  },
  {
    name: '购买短信',
    path: '/webview/lgj/settlement/message/buy',
    props: {
      url: `${LiguanjiaUrl}/team_message/buy`,
    },
  },
  {
    name: '短信签名申请',
    path: '/webview/lgj/settlement/message/signature/add',
    props: {
      url: `${LiguanjiaUrl}/message_sn/add`,
    },
  },
  {
    name: '短信签名管理',
    path: '/webview/lgj/settlement/message/signature/apply',
    props: {
      url: `${LiguanjiaUrl}/message_sn/lst`,
    },
  },
  {
    name: '商城短信签名列表',
    path: '/webview/lgj/settlement/message/signature/store',
    props: {
      url: `${LiguanjiaUrl}/team_message/store_list`,
    },
  },
  {
    name: '商城额度管理',
    path: '/webview/lgj/mall/credit/account',
    props: {
      url: `${LiguanjiaUrl}/finance/store_finance`,
    },
  },
  {
    name: '额度明细',
    path: '/webview/lgj/mall/credit/details',
    props: {
      url: `${LiguanjiaUrl}/finance/store_finance_detail`,
    },
  },
  {
    name: '开票信息维护',
    path: '/webview/lgj/mall/setting/invoice/info',
    props: {
      url: `${LiguanjiaUrl}/invoice/info`,
    },
  },
  {
    name: '收票地址设置',
    path: '/webview/lgj/mall/setting/invoice/address_list',
    props: {
      url: `${LiguanjiaUrl}/invoice/address_list`,
    },
  },
  {
    name: '开票记录',
    path: '/webview/lgj/mall/setting/invoice/recoder',
    props: {
      url: `${LiguanjiaUrl}/invoice/recoder?type=product`,
    },
  },
  {
    name: '订单数据',
    path: '/webview/lgj/data/order',
    props: {
      url: `${LiguanjiaUrl}/census/trade_data`,
    },
  },
  {
    name: '用户数据',
    path: '/webview/lgj/data/user',
    props: {
      url: `${LiguanjiaUrl}/census/user_data`,
    },
  },
  {
    name: '商品数据',
    path: '/webview/lgj/data/goods',
    props: {
      url: `${LiguanjiaUrl}/census/goods_data`,
    },
  },
  {
    name: '消息中心',
    path: '/webview/lgj/notice/list',
    props: {
      url: `${LiguanjiaUrl}/team_notice/lists`,
    },
  },
  {
    name: '选品列表',
    path: '/webview/lgj/dispatch/productList',
    props: {
      url: `${LiguanjiaUrl}/dispatch/productList`,
    },
  },
  {
    name: '已选商品',
    path: '/webview/lgj/dispatch/myProduct',
    props: {
      url: `${LiguanjiaUrl}/dispatch/myProduct`,
    },
  },
  {
    name: '批量下单',
    path: '/webview/lgj/dispatch/batchOrder',
    props: {
      url: `${LiguanjiaUrl}/dispatch/batchOrder`,
    },
  },
  {
    name: '下单历史',
    path: '/webview/lgj/dispatch/batchHistory',
    props: {
      url: `${LiguanjiaUrl}/dispatch/batchHistory`,
    },
  },
  {
    name: '变更通知',
    path: '/webview/lgj/dispatch/priceChange',
    props: {
      url: `${LiguanjiaUrl}/dispatch/priceChange`,
    },
  },
  {
    name: '下单通知',
    path: '/webview/lgj/dispatch/noticeMobile',
    props: {
      url: `${LiguanjiaUrl}/dispatch/noticeMobile`,
    },
  },
  {
    name: '下单通知',
    path: '/webview/lgj/dispatch/noticeMobile',
    props: {
      url: `${LiguanjiaUrl}/dispatch/noticeMobile`,
    },
  },
  {
    name: '下单通知',
    path: '/webview/lgj/dispatch/noticeMobile',
    props: {
      url: `${LiguanjiaUrl}/dispatch/noticeMobile`,
    },
  },
  {
    name: '老账户管理',
    path: '/webview/lgj/finance/history_team_finance_index',
    props: {
      url: `${LiguanjiaUrl}/finance/history_team_finance_index`,
    },
  },
  {
    name: '飞象企服发票',
    path: '/webview/lgj/invoice/history_index',
    props: {
      url: `${LiguanjiaUrl}/invoice/history_index`,
    },
  },
  {
    name: '分期商城',
    path: '/webview/lgj/apply_store/fq',
    props: {
      url: `${LiguanjiaUrl}/apply_store/fq`,
    },
  },
  {
    name: '3.0交易明细',
    path: '/webview/lgj/settlement/dealings/history/list',
    props: {
      url: `${LiguanjiaUrl}/finance/history_finance_detail`,
    },
  },
  {
    name: '3.0交易汇总',
    path: '/webview/lgj/settlement/dealings/history/summary',
    props: {
      url: `${LiguanjiaUrl}/finance/history_finance_summary`,
    },
  },
  {
    name: '虚拟商品编辑',
    path: '/webview/lgj/product/edit',
    props: {
      url: `${LiguanjiaUrl}/product/edit`,
      parseQueryToMatch: true,
    },
  },
  {
    name: '创建短信签名',
    path: '/webview/lgj/settlement/message/signature/add',
    props: {
      url: `${LiguanjiaUrl}/message_sn/add`,
    },
  },
  // 妙鲤严选
  {
    name: '信息变更申请',
    path: '/webview/supplier/product_change/examine_list',
    props: {
      url: `${SupplierUrl}/product_change/examine_list`,
      isSupplier: true,
    },
  },
  {
    name: '区域限制',
    path: '/webview/supplier/supplierDistributionArea',
    props: {
      url: `${SupplierUrl}/supplier/supplierDistributionArea`,
      isSupplier: true,
    },
  },
  {
    name: '商品统一说明',
    path: '/webview/supplier/product/supplier_product_ext',
    props: {
      url: `${SupplierUrl}/product/supplier_product_ext`,
      isSupplier: true,
    },
  },
  {
    name: '订单概况',
    path: '/webview/supplier/order/generalSituation',
    props: {
      url: `${SupplierUrl}/order/generalSituation`,
      isSupplier: true,
    },
  },
  {
    name: '订单管理',
    path: '/webview/supplier/esOrder/index',
    props: {
      url: `${SupplierUrl}/esOrder/index`,
      isSupplier: true,
    },
  },
  {
    name: '订单售后',
    path: '/webview/supplier/order/refund_order_list',
    props: {
      url: `${SupplierUrl}/order/refund_order_list`,
      isSupplier: true,
    },
  },
  {
    name: '订单退货地址',
    path: '/webview/supplier/wl_setting',
    props: {
      url: `${SupplierUrl}/supplier/wl_setting`,
      isSupplier: true,
    },
  },
  {
    name: '对账结算',
    path: '/webview/supplier/capital/accountListV2',
    props: {
      url: `${SupplierUrl}/capital/accountListV2`,
      isSupplier: true,
    },
  },
  {
    name: '质保金',
    path: '/webview/supplier/capital/accountWarranty',
    props: {
      url: `${SupplierUrl}/capital/accountWarranty`,
      isSupplier: true,
    },
  },
];

const Webview: React.FC = () => {
  const [targetUrl, setTargetUrl] = useState('');

  const location = useLocation();
  const navigate = useTabNavigate();

  const { url, needLGJtoken, isSupplier, width, height, needCenter, parseQueryToMatch } =
    useMemo(() => {
      const found = webviewList.find((item) => item.path === location.pathname);

      if (!found) {
        navigate('/404', { replace: true });
      }

      if (found?.props.parseQueryToMatch) {
        found.props.url = `${found.props.url}${location.search}`;
      }

      return found?.props || {};
    }, [location]);

  const formatQuery = (queryString: string) => {
    const queryPairs = queryString.slice(1).split('&');
    const values = queryPairs.map((pair) => pair.split('=')[1]);
    const path = `/${values.join('/')}`;
    return path;
  };

  const appendTokenToURL = (url: string) => {
    const token = Cookies.get(TokenKey);
    const liguanjiaToken = Cookies.get('liguanjia_member_token');
    const cardToken = Cookies.get('liguanjia_card_token');
    const productToken = Cookies.get('liguanjia_product_token');

    const queryParams = [`hideMenu=1`];

    if (needLGJtoken) {
      queryParams.push(`token=${liguanjiaToken}`, `liye_token=${token}`, `card_token=${cardToken}`, `product_token=${productToken}`);
    } else if (isSupplier) {
      queryParams.push(`pangu_token=${token}`);
    } else {
      queryParams.push(`token=${token}`, `product_token=${productToken}`);
    }

    if (window.sessionStorage.getItem('postParam')) {
      const postParam: any = window.sessionStorage.getItem('postParam');
      queryParams.push(postParam);
      // 用完就清除
      window.sessionStorage.removeItem('postParam');
    }

    const queryString = queryParams.join('&');

    if (url.includes('?')) {
      if (parseQueryToMatch) {
        const query = url.split('?')[1];
        const match = formatQuery(`?${query}`);
        setTargetUrl(`${url.split('?')[0]}${match}?${queryString}`);
      } else {
        setTargetUrl(`${url}&${queryString}`);
      }
    } else {
      setTargetUrl(`${url}?${queryString}`);
    }
  };

  useEffect(() => {
    const handlePostMessage = (event: any) => {
      if (event?.data?.type === 'link' && event?.data?.data) {
        navigate(event?.data?.data);

        // 有一些跳转需要额外参数，格式：activeTab=2
        if (event?.data?.param) {
          window.sessionStorage.setItem('postParam', event?.data?.param);
        }
      }
    };

    if (url) {
      window.addEventListener('message', handlePostMessage, false);
      appendTokenToURL(url || '');
      return () => {
        window.removeEventListener('message', handlePostMessage);
      };
    }
  }, [url]);

  return (
    <div
      style={
        needCenter
          ? { display: 'flex', justifyContent: 'center', height: '100%' }
          : { height: '100%' }
      }
    >
      <Iframe
        url={targetUrl}
        width={width || '100%'}
        height={height || '100%'}
        frameBorder={0}
      />
    </div>
  );
};

export default Webview;
