import React, { useState } from 'react';
import PlusRouter from '@fle-ui/plus-router';
import ProProvider from '@fle-ui/plus-context';
import routerConfig from '@/const/route.config';

interface WujieReactItem {
  path: string;
  pathname: string;
  singleTab?: boolean;
  WujieReactDom: React.ReactElement;
}

interface ProProviderProps {
  wujieReactList: WujieReactItem[];
  setWujieReactList: React.Dispatch<React.SetStateAction<WujieReactItem[]>>;
}

const RouterPack: React.FC = () => {
  const { Provider } = ProProvider;
  const [wujieReactList, setWujieReactList] = useState<WujieReactItem[]>([]);

  const contextValue: ProProviderProps = {
    wujieReactList,
    setWujieReactList,
  };
 
  return (
    <Provider value={contextValue}>
      <PlusRouter routes={routerConfig} />
    </Provider>
  );
};

export default RouterPack;
