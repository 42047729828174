import React from 'react';
import { Breadcrumb } from 'antd';
import { RightOutlined } from '@ant-design/icons';

import type { MenuDataItem } from '@/const/route.config';

import './index.less';

interface BreadcrumbProps {
  currentMenu?: MenuDataItem | null;
  matchMenu: MenuDataItem[];
}

const CustomBreadcrumb: React.FC<BreadcrumbProps> = ({ currentMenu, matchMenu }) => {
  if (!currentMenu?.showBreadcrumb) {
    return null;
  }

  const matchMenuLength = matchMenu?.length - 2;
  const filterMenu = [...matchMenu];
  filterMenu.splice(0, Math.min(matchMenuLength, 2));

  const renderMenuItems = filterMenu.map((item, index, arr) => {
    if (index === arr.length - 1) {
      return {
        title: item.name,
      };
    }
    return {
      href: item.redirect || item.path,
      title: item.name,
    };
  });

  if (!renderMenuItems?.length) return null;

  return (
    <div className="breadcrumb-wrap">
      <Breadcrumb separator={<RightOutlined style={{ fontSize: 12 }} />} items={renderMenuItems} />
    </div>
  );
};
export default React.memo(CustomBreadcrumb);
