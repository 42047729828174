export default {
  memberInfo: '/elebuys/member/employee/member-info', // 获取账号信息
  openedRightList: '/elebuys/member/rights/opened-rights-list', // 企业已开通的权益列表
  logout: '/elebuys/right/login/logout', // 退出登录
  logoutOther: '/welcome/logout', // 3.0退出登录
  fetchLiguanjiaToken: '/welcome/loginByToken', // 获取3.0token
  fetchMiaohuoToken: '/elebuys/user/login/v2/enter-company-miaohuo', // 获取妙货token
  getApplictionPermission: '/elebuys/jack/right/userSystemPermission', // 获取应用权限
  getMenuPermission: '/elebuys/right/v2/userRole/miaoLiUserSystemPermissionTree', // 获取菜单权限
  // getMenuPermission: '/elebuys/right/v2/userRole/userSystemPermissionTree', // 获取菜单权限
  getUserPermission: '/elebuys/right/v2/userRole/getUserPermissionByPermissionKey', // 根据PermissionKey获取用户权限
  getSiteId: 'fleshopy/merchant/site/siteDecoration/getUsableInfo', // 获取站点id
  getCompanyList: '/elebuys/user/login/v2/employee-info-carp-dance', // 获取该用户下所有公司
  changeCompany: '/elebuys/user/login/v2/enter-company-carp-dance', // 切换公司
  getTeamHistory: '/team/getTeamHistory', // 是否3.0老会员
  createCompany: '/elebuys/member/company/create', // 创建企业
  getTeamInfo: '/Company/teamInfo', // 读取3.0会员信息接口
  companyEquity: '/elebuys/member/rights/highest', // 读取权益信息
  getTeamSelfInfo: '/team/getTeamInfo', // 是否展示自有京东
};
