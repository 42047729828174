import React from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import { useTabNavigate } from '@/hooks/useTabNavigate';

export default function TabLink(props: LinkProps) {
  const navigate = useTabNavigate();
  return (
    <Link
      {...props}
      to={props.to}
      onClick={async (e) => {
        e.preventDefault();
        let prevent = false;

        await props.onClick?.({
          ...e,
          preventDefault() {
            prevent = true;
          },
        });

        if (prevent) {
          return;
        }

        const { target = '_self' } = props;

        if (target === '_self') {
          navigate(props.to);
          return;
        }

        window.open(`${props.to}`, target);
      }}
    >
      {props.children}
    </Link>
  );
}
