/* eslint-disable no-redeclare */
import { PageCacheStore } from '@/stores';
import { MAX_TAB_PAGE_COUNT } from '@/stores/pageCacheStore';
import { message } from 'antd';
import { NavigateOptions, To, useNavigate } from 'react-router-dom';

export function useTabNavigate() {
  const _navigate = useNavigate();

  function navigate(to: To, options?: NavigateOptions): void;
  function navigate(delta: number): void;
  function navigate(to: any, options?: any) {
    if (
      PageCacheStore.alivePages.length >= MAX_TAB_PAGE_COUNT &&
      to !== '/module/workbench/dashboard' &&
      !PageCacheStore.alivePages.some((item) => item.key === to)
    ) {
      message.info('当前打开的标签页过多，请关闭部分页面再重试！');
      return;
    }
    _navigate(to, options);
  }

  return navigate;
}
