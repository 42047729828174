import { computed, makeAutoObservable, runInAction } from 'mobx';
import WujieReact from 'wujie-react';

import { ApplicationInfo } from '@/const/application';

const { destroyApp } = WujieReact;
const { dashboard } = ApplicationInfo;

const filterPageTab = [dashboard, '/redirectPage'];

export interface IPageInfo {
  /** 默认为path */
  key: string;
  /** 名称 */
  name?: string;
  /** 只允许打开单个标签 */
  singleTab?: boolean;
}

export const MAX_TAB_PAGE_COUNT = 30;

class PageCacheStore {
  homePage: IPageInfo = {
    key: '/module/workbench/dashboard',
    singleTab: true,
  };

  tabsPages: IPageInfo[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  updatePageTab(info: IPageInfo) {
    const { key } = info;

    if (filterPageTab.includes(key)) return;

    const currentTabIndex = this.getCurrentTabIndex(key);

    // 更新多标签页
    if (currentTabIndex > -1) {
      const newTabsPages = this.tabsPages.map((item) => {
        if (item.key === key) {
          return {
            ...info,
          };
        }
        return item;
      });

      runInAction(() => {
        this.tabsPages = newTabsPages;
      });
    } else {
      // 新增标签页
      runInAction(() => {
        this.tabsPages = [...this.tabsPages, info];
      });
    }
  }

  /**
   * 刷新标签页
   * @param path 标签页path
   */
  refreshPage = (path: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const refreshIndex = this.getCurrentTabIndex(path);

      if (typeof refreshIndex === 'number') {
        destroyApp(path);

        resolve(path);
      }
      reject();
    });
  };

  /**
   * 关闭标签页
   * @param path 标签页path
   */
  closePageTab = (path: string, signleTab = false): Promise<string> => {
    return new Promise((resolve, reject) => {
      const refreshIndex = this.getCurrentTabIndex(path);

      if (typeof refreshIndex === 'number') {
        const newTabsPages = this.tabsPages.filter((item) => item.key !== path);

        const { pathname, search } = window.location;
        let redirectPath = '';

        if (path === `${pathname}${signleTab ? '' : search}`) {
          redirectPath = newTabsPages[newTabsPages.length - 1]?.key || this.homePage?.key;
        }

        runInAction(() => {
          destroyApp(path);
          this.tabsPages = newTabsPages;
        });

        resolve(redirectPath);
      }
      reject();
    });
  };

  /**
   * 关闭所有标签页
   */
  closeAllTabs() {
    runInAction(() => {
      const copyTabsPages = [...this.tabsPages];

      copyTabsPages.forEach((item) => {
        destroyApp(item.key);
      });
      this.tabsPages = [];
    });
  }

  getCurrentTabIndex(key?: string) {
    const { pathname, search } = window.location;
    return this.tabsPages.findIndex((item) => item.key === (key || `${pathname}${search}`));
  }

  @computed
  get alivePages() {
    return this.tabsPages;
  }

  @computed
  get currentTabIndex() {
    const { pathname, search } = window.location;
    return this.tabsPages.findIndex((item) => item.key === `${pathname}${search}`);
  }
}

export default new PageCacheStore();
