import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import PlusConfigProvider from '@fle-ui/plus-config-provider';
import Router from '@/router';
import { fleAppEnv } from './const/serviceEnv';
// import Cookies from 'js-cookie';

import '@/theme/reset.less';
import '@/style/index.less';

// [
//   'liguanjia_member_token',
//   'liguanjia_supplier_token',
//   'liguanjia_card_token',
//   'liguanjia_member_token',
// ].forEach(async (item: string) => {
//   await Cookies.remove(item);
// });

const container = document.getElementById('root');

const root = ReactDOM.createRoot(container!);
window.localStorage.setItem('env', fleAppEnv || '');

root.render(
  <PlusConfigProvider>
    <Router />
  </PlusConfigProvider>,
);
