type actionType = 'cscUser' | 'csc' | 'message' | 'importAndExport' | 'vetting';
export interface ApplicationItemType {
  systemId: string;
  logo: string;
  textLogo: string;
  title: string;
  description?: string;
  dashboard: string;
  actionsIcon: actionType[];
}

export const ApplicationInfo: ApplicationItemType = {
  systemId: '100005',
  title: '万鲤商联',
  logo: '//oss.elebuys.com/logo/carpunion/only-logo.png',
  textLogo: '//oss.elebuys.com/logo/carpunion/text-logo.png',
  description: '万鲤商联',
  dashboard: '/module/workbench/dashboard', // 首页
  actionsIcon: ['cscUser', 'importAndExport', 'message'],
};
