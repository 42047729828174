import React from 'react';
import classNames from 'classnames';
import { TabLink } from '@/components';
import Icon from '@/components/Icon';

import type { MenuDataItem } from '@/const/route.config';

import './index.less';

interface HeaderMenuProps {
  menuData: MenuDataItem[];
  matchMenuKeys?: string[];
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ menuData, matchMenuKeys = [] }) => {
  return (
    <div className="fle-layout-header-menu">
      {menuData
        .filter((item) => !item.hideInMenu && item.path !== '/group/console')
        .map((item) => {
          const { menuTag } = JSON.parse(decodeURIComponent(item?.props || '{}'));
          return item.name ? (
            <TabLink
              key={item.path}
              className={classNames('fle-layout-header-menu-item', {
                active: matchMenuKeys.includes(item.path || ''),
              })}
              to={item.redirect || item.path}
            >
              {item.icon && <Icon type={item.icon} />}
              <span className="fle-layout-header-menu-item-name">{item.name}</span>
              {menuTag && <span className="fle-layout-header-menu-item-tag">{menuTag}</span>}
              {/* <div className="fle-layout-header-menu-item-line" /> */}
            </TabLink>
          ) : null;
        })}
    </div>
  );
};
export default React.memo(HeaderMenu);
