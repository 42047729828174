import { MenuDataItem } from '@/const/route.config';

export const handleHasPreviousLevel = (data: MenuDataItem[], flag: boolean) => {
  if (flag) {
    // 3.0老会员菜单
    const target = data.find((obj: any) => obj.name === '工作台');
    target?.children?.push({
      name: '老数据',
      path: '/carpdance/koudaiqifu',
      icon: 'icon-laoshuju_xuanzhong1',
      redirect: '/webview/lgj/finance/history_team_finance_index',
      layout_keys: ['/group/workbench', '/carpdance/koudaiqifu'],
      layout_names: '妙鲤-工作台-飞象企服平台老数据',
      hideChildrenInMenu: false,
      hideInMenu: false,
      permission: true,
      permissionKey: '',
      description: '',
      pureMenu: false,
      resourceType: 1,
      routerType: 'iframe',
      target: '_self',
      sort: 9,
      props: '',
      id: 103001,
      children: [
        {
          name: '飞象企服平台老数据',
          path: '/webview/lgj/finance/history_team_finance_index',
          icon: '',
          redirect: '',
          layout_keys: [
            '/group/workbench',
            '/carpdance/koudaiqifu',
            '/webview/lgj/finance/history_team_finance_index',
          ],
          layout_names: '妙鲤-工作台-飞象企服平台老数据-老账户管理',
          hideChildrenInMenu: true,
          hideInMenu: false,
          permission: true,
          permissionKey: '',
          description: '',
          pureMenu: false,
          resourceType: 1,
          routerType: 'iframe',
          showBreadcrumb: false,
          target: '_self',
          sort: 1,
          props: '',
          id: 1030018,
          children: [
            {
              name: '老账户管理',
              path: '/webview/lgj/finance/history_team_finance_index',
              icon: '',
              redirect: '',
              layout_keys: [
                '/group/workbench',
                '/carpdance/koudaiqifu',
                '/webview/lgj/finance/history_team_finance_index',
              ],
              layout_names: '妙鲤-工作台-飞象企服平台老数据',
              hideChildrenInMenu: false,
              hideInMenu: false,
              permission: true,
              permissionKey: '',
              description: '',
              pureMenu: false,
              resourceType: 1,
              routerType: 'iframe',
              target: '_self',
              sort: 2,
              props: '',
              id: 1030012,
              showBreadcrumb: false,
              children: [],
            },
            {
              name: '飞象企服发票',
              path: '/webview/lgj/invoice/history_index',
              icon: '',
              redirect: '',
              layout_keys: [
                '/group/workbench',
                '/carpdance/koudaiqifu',
                '/webview/lgj/invoice/history_index',
              ],
              layout_names: '妙鲤-工作台-飞象企服平台老数据-飞象企服发票',
              hideChildrenInMenu: false,
              hideInMenu: false,
              permission: true,
              permissionKey: '',
              description: '',
              pureMenu: false,
              resourceType: 1,
              routerType: 'iframe',
              target: '_self',
              sort: 2,
              props: '',
              id: 1030012,
              showBreadcrumb: false,
              children: [],
            },
          ],
        },
      ],
    });
  }
};
export const handleSelfJdHasPreviousLevel = (data: MenuDataItem[], flag: boolean) => {
  if (flag) {
    // 自有京东
    const target = data.find((obj: any) => obj.name === '工作台');
    target?.children?.push({
      name: '自有京东',
      path: '/interface',
      icon: 'icon-ziyoujingdong_xuanzhong1',
      redirect: '/module/vop/interface/goods',
      layout_keys: ['/group/workbench', '/interface/goods'],
      layout_names: '妙鲤-工作台-自有京东',
      hideChildrenInMenu: false,
      hideInMenu: false,
      permission: true,
      permissionKey: '',
      description: '',
      pureMenu: false,
      resourceType: 2,
      routerType: 'micro',
      target: '_self',
      sort: 10,
      props: '',
      id: 1030011,
      children: [
        {
          name: '自有京东',
          path: '/module/vop/interface/goods',
          icon: '',
          redirect: '',
          layout_keys: ['/group/workbench', '/interface', '/module/vop/interface/goods'],
          layout_names: '妙鲤-工作台-自有京东-接口商品管理',
          hideChildrenInMenu: true,
          hideInMenu: false,
          permission: true,
          permissionKey: '',
          description: '',
          pureMenu: false,
          resourceType: 2,
          routerType: 'micro',
          showBreadcrumb: false,
          target: '_self',
          sort: 1,
          props: '',
          id: 10300182,
          children: [
            {
              name: '接口商品管理',
              path: '/module/vop/interface/goods',
              icon: '',
              redirect: '',
              layout_keys: ['/group/workbench', '/interface', '/module/vop/interface/goods'],
              layout_names: '妙鲤-工作台-接口商品管理',
              hideChildrenInMenu: false,
              hideInMenu: false,
              permission: true,
              permissionKey: '',
              description: '',
              pureMenu: false,
              resourceType: 2,
              routerType: 'micro',
              target: '_self',
              sort: 3,
              props: '',
              id: 10300122,
              showBreadcrumb: false,
              children: [],
            },
            {
              name: '接口余额明细',
              path: '/module/vop/interface/balance',
              icon: '',
              redirect: '',
              layout_keys: ['/group/workbench', '/interface', '/module/vop/interface/balance'],
              layout_names: '妙鲤-工作台-接口余额明细',
              hideChildrenInMenu: false,
              hideInMenu: false,
              permission: true,
              permissionKey: '',
              description: '',
              pureMenu: false,
              resourceType: 2,
              routerType: 'micro',
              target: '_self',
              sort: 4,
              props: '',
              id: 10300123,
              showBreadcrumb: false,
              children: [],
            },
            {
              name: '接口对账明细',
              path: '/module/vop/interface/account',
              icon: '',
              redirect: '',
              layout_keys: ['/group/workbench', '/interface', '/module/vop/interface/account'],
              layout_names: '妙鲤-工作台-接口对账明细',
              hideChildrenInMenu: false,
              hideInMenu: false,
              permission: true,
              permissionKey: '',
              description: '',
              pureMenu: false,
              resourceType: 2,
              routerType: 'micro',
              target: '_self',
              sort: 5,
              props: '',
              id: 10300125,
              showBreadcrumb: false,
              children: [],
            },
            {
              name: '下单失败明细',
              path: '/module/vop/interface/orderFail',
              icon: '',
              redirect: '',
              layout_keys: ['/group/workbench', '/interface', '/module/vop/interface/orderFail'],
              layout_names: '妙鲤-工作台-下单失败明细',
              hideChildrenInMenu: false,
              hideInMenu: false,
              permission: true,
              permissionKey: '',
              description: '',
              pureMenu: false,
              resourceType: 2,
              routerType: 'micro',
              target: '_self',
              sort: 6,
              props: '',
              id: 1030012,
              showBreadcrumb: false,
              children: [],
            },
            {
              name: '接口配置',
              path: '/module/vop/interface/setting',
              icon: '',
              redirect: '',
              layout_keys: ['/group/workbench', '/interface', '/module/vop/interface/setting'],
              layout_names: '妙鲤-工作台-接口配置',
              hideChildrenInMenu: false,
              hideInMenu: false,
              permission: true,
              permissionKey: '',
              description: '',
              pureMenu: false,
              resourceType: 2,
              routerType: 'micro',
              target: '_self',
              sort: 7,
              props: '',
              id: 10300166,
              showBreadcrumb: false,
              children: [],
            },
          ],
        },
      ],
    });
  }
};
