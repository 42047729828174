import React, { useEffect, useState } from 'react';
import { Tooltip, Badge, Button } from 'antd';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import NP from 'number-precision';
import Cookies from 'js-cookie';

import { Icon } from '@/components';
import PageTabs from '../PageTabs';
import { useTabNavigate } from '@/hooks/useTabNavigate';
import NoticeDrawer from '@/layout/NoticeDrawer';
import { CommonStore } from '@/stores';
import { getApplicationUrl } from '@/const/serviceEnv';
import { RELATION_TYPES, PLATFORM_TYPES } from '@/const/csc';

import type { MenuDataItem } from '@/const/route.config';
import type { UserInfo } from '@/stores/account';

import './index.less';

interface SubMenuProps {
  userInfo?: UserInfo;
  currentMenu?: MenuDataItem;
  matchMenu?: MenuDataItem[];
  matchMenuKeys?: string[];
}

const SiderMenu: React.FC<SubMenuProps> = ({ userInfo, currentMenu, matchMenu, matchMenuKeys }) => {
  const navigator = useTabNavigate();

  const { csInfo } = CommonStore;
  const hasCscRights =
    csInfo.csRight && ((csInfo.hasSeat && csInfo.seatStatus === 1) || csInfo.isAdmin);
  const [messageOpen, setMessageOpen] = useState<boolean>(false);
  const [messageNum, setMessageNum] = useState<number | null>(null);

  // 获取消息数量
  const getMessageCountNum = async () => {
    const param = {
      channelType: 10,
      isRead: -10, // 未读数量
    };
    const data: any = await CommonStore.remindCountNum(param);

    setMessageNum(NP.plus(data?.systemNum, data?.businessNum));
  };

  // const [awaitApproveCount, setAwaitApproveCount] = useState<number>(0);
  // const getApproveCountNum = async () => {
  //   const data: any = await CommonStore.tabStatistical({});
  //   setAwaitApproveCount(data.awaitApproveCount);
  // };

  useEffect(() => {
    // getApproveCountNum();
    getMessageCountNum();
  }, []);

  const firstMenu = matchMenu?.[0];
  const subMenus = firstMenu?.children || [];

  if (firstMenu?.hideChildrenInMenu || subMenus.length === 0 || currentMenu?.pureMenu) return null;

  if (subMenus?.length === 0) return null;

  const { showPageTab, menuType } = JSON.parse(decodeURIComponent(firstMenu?.props || '{}'));

  let activeMenu: MenuDataItem | undefined;
  let activeMenuChild: MenuDataItem[] = [];

  if (menuType === 'twoRowMenu') {
    activeMenu = subMenus?.find((item) => matchMenuKeys?.includes(item.path));
    activeMenuChild = activeMenu?.children || [];
  }

  const collapsed =
    (!activeMenu?.children?.length || activeMenu?.hideChildrenInMenu === true) &&
    menuType !== 'oneRowMenu';

  const style = collapsed ? { width: 64 } : { width: 180 };

  const oneRowMenuCellCom = (item: MenuDataItem) => (
    <div
      key={item.path}
      onClick={() => navigator(item.redirect || item.path)}
      className={classNames('fle-layout-sider-one-menu-cell', {
        active: matchMenuKeys?.includes(item.path),
      })}
    >
      {item.icon && <Icon type={item.icon} />}
      {item.name}
    </div>
  );

  const oncRowMenuGroupDom = (item: MenuDataItem) => (
    <div key={item.path} className="fle-layout-sider-one-menu-group">
      <div className="fle-layout-sider-one-menu-group-name">{item.name}</div>
      {item.children?.map((route) => oneRowMenuCellCom(route))}
    </div>
  );

  const oncRowMenuBtnDom = (item: MenuDataItem) => {
    let dom: React.ReactNode = <React.Fragment />;

    switch (item.path) {
      case '/module/lgjMall/createShop':
        dom = (
          <img
            onClick={() => navigator('/module/lgjMall/createShop')}
            src="https://oss.elebuys.com/resource/goods/materials/202405301545530001331339.png"
            alt="创建商城"
          />
        );
        break;
      case '/module/lgjMall/application':
        dom = (
          <img
            onClick={() => navigator('/module/lgjMall/application')}
            src="https://oss.elebuys.com/resource/goods/materials/202405301545530001331340.png"
            alt="管理商城"
          />
        );
        break;
      case '/group/product':
        dom = (
          <img
            onClick={() => navigator('/module/lgjProduct/index')}
            src="https://oss.elebuys.com/wowcarp_market.png"
            alt="妙鲤集市"
          />
        );
        break;
      case '/module/program/chat':
        dom = (
          <img
            onClick={() => navigator('/module/program/chat')}
            style={{ borderRadius: 8 }}
            src="https://oss.elebuys.com/resource/goods/materials/202406051526130005155496.png"
            alt="AI制作方案"
          />
        );
        break;
      default:
        dom = (
          <Button block type="default" onClick={() => navigator(item.redirect || item.path)}>
            {item.name}
          </Button>
        );
        break;
    }

    return (
      <div className="fle-layout-sider-one-menu-btn" key={item.path}>
        {dom}
      </div>
    );
  };

  return (
    <>
      {menuType && (
        <div className="fle-layout-sider">
          <div className="fle-layout-sider-space" style={style} />
          {menuType === 'oneRowMenu' && (
            <div className="fle-layout-sider-one-menu fle-scroll">
              <div className="fle-layout-sider-one-menu-list">
                {subMenus?.map((item) => {
                  if (item.hideInMenu) {
                    return null;
                  }

                  const { menuType } = JSON.parse(decodeURIComponent(item?.props || '{}'));

                  let dom: React.ReactNode = <React.Fragment />;
                  switch (menuType) {
                    case 'btn':
                      dom = oncRowMenuBtnDom(item);
                      break;
                    case 'group':
                      dom = oncRowMenuGroupDom(item);
                      break;
                    default:
                      dom = oneRowMenuCellCom(item);
                      break;
                  }
                  return dom;
                })}
              </div>
            </div>
          )}

          {menuType === 'twoRowMenu' && (
            <>
              <div className="fle-layout-sider-wrap">
                <div className="fle-layout-sider-first-menu fle-scroll">
                  {subMenus
                    ?.filter((item) => !item.hideInMenu)
                    .map((item) => {
                      const active = matchMenuKeys?.includes(item.path);
                      const selectedIcon = JSON.parse(
                        decodeURIComponent(item.props || '{}'),
                      )?.selectedIcon;

                      return (
                        <span
                          key={item.path}
                          onClick={() => navigator(item.redirect || item.path)}
                          className={classNames('fle-layout-sider-first-menu-item', {
                            active,
                          })}
                        >
                          {item.icon && <Icon type={item.icon as string} className="menu-icon" />}
                          {selectedIcon && (
                            <Icon type={selectedIcon} className="selected-menu-icon" />
                          )}
                          <span>{item.name}</span>
                        </span>
                      );
                    })}
                </div>
                <div className="fle-layout-sider-actions">
                  {/* 此处客服暂时隐藏 */}
                  {hasCscRights && (
                    <Tooltip title="商城客服" placement="right">
                      <Icon
                        type="icon-kefu-moren"
                        className="fle-layout-sider-action"
                        onClick={() => {
                          const cscUrl = getApplicationUrl('csc');
                          const params: any = {
                            platform_type: PLATFORM_TYPES.carpunion_cs,
                            token: Cookies.get('token') || '',
                            redirectUrl: encodeURIComponent(window.location.origin),
                          };
                          const paramsStr = Object.keys(params)
                            .map((key) => `${key}=${params[key]}`)
                            .join('&');
                          window.open(`${cscUrl}/?${paramsStr}`, 'CscWindowShop');
                        }}
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="消息中心" placement="right">
                    <Badge size="small" count={messageNum} offset={[0, 12]}>
                      <Icon
                        type="icon-xiaoxi-moren"
                        className="fle-layout-sider-action"
                        onClick={() => {
                          setMessageOpen(true);
                        }}
                      />
                    </Badge>
                  </Tooltip>
                  {/* <Tooltip title="审批中心" placement="right">
                  <Badge size="small" count={awaitApproveCount} offset={[0, 12]}>
                    <Icon
                      type="icon-shenpi-moren"
                      className="fle-layout-sider-action"
                      onClick={() => navigator('/module/common/vetting/500')}
                    />
                  </Badge>
                </Tooltip> */}
                  {/* <Tooltip title="导入导出" placement="right">
                <Icon
                  type="icon-daochu-moren"
                  className="fle-layout-sider-action"
                  onClick={() => navigator('/module/common/importAndExport/1')}
                />
              </Tooltip> */}
                </div>
              </div>
              {!collapsed && (
                <div className="fle-layout-sider-second-menu fle-scroll">
                  {activeMenuChild
                    .filter((item) => !item.hideInMenu)
                    .map((item, index) => {
                      return (
                        <div className="second-menu-group" key={item.path}>
                          {!!index && <div className="second-menu-line" />}
                          <div className="second-menu-group-title">
                            {item.icon && <Icon type={item.icon} className="second-menu-icon" />}
                            <span className="menu-group-title">{item.name}</span>
                          </div>
                          <div className="second-menu-list">
                            {item.children
                              ?.filter((route) => !route.hideInMenu)
                              .map((route) => {
                                return (
                                  <div
                                    key={route.path}
                                    onClick={() => navigator(route.redirect || route.path)}
                                    className={classNames('second-menu-item', {
                                      active: matchMenuKeys?.includes(route.path),
                                    })}
                                  >
                                    {route.icon && (
                                      <Icon
                                        type={route.icon}
                                        className="second-menu-icon"
                                        style={{ fontSize: 16 }}
                                      />
                                    )}
                                    <span className="second-menu-name">{route.name}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </>
          )}
        </div>
      )}

      {showPageTab && <PageTabs />}

      <NoticeDrawer
        open={messageOpen}
        setMessageOpen={setMessageOpen}
        setMessageNum={setMessageNum}
      />
    </>
  );
};
export default observer(SiderMenu);
