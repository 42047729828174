export const POSITIONS = {
  // 营销云商城首页
  goodsDetail: 1,
  // 营销云商城首页
  marketHome: 2,
  // 营销云智能名片
  businessCard: 3,
  // 百鲤跃川官网
  carpdanceOfficial: 4,
  // 百鲤跃川产业平台
  carpdancePlatform: 5,
};

export const RELATION_TYPES = {
  // 营销云
  market: 0,
  // 站点
  site: 1,
  // 卡券
  coupons: 2,
  // 百鲤跃川平台
  carpdancePlatform: 3,
  // 百鲤跃川官网
  carpdanceOfficial: 4,
};

export const PLATFORM_TYPES = {
  pointsShop: '201001', // 万鲤商联-积分商城-用户
  cashShop: '201002', // 万鲤商联-现金商城-用户
  stageShop: '201003', // 万鲤商联-分期商城-用户
  card: '201004', // 万鲤商联-礼包卡券-用户
  carpunion_cs: '201000', // 万鲤商联-会员平台-客服

  marketCloudShop: '101001', // 智鲤-营销云-用户
  smartercarp_cs: '101000', // 智鲤-会员平台-客服

  carpdance_cs: '301000', // 百鲤跃川-平台客服
  carpdance: '302000', // 百鲤跃川-官网
  carpdance_user: '302001', // 百鲤跃川-咨询-用户
};
