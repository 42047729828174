import { pathToRegexp } from 'path-to-regexp';
import type { MenuDataItem } from '@/const/route.config';
import { isUrl, stripQueryStringAndHashFromPath } from './transformRoute';

export const getAuthPath = (
  item: MenuDataItem,
  flatMenus: Record<string, MenuDataItem> | null,
): string => {
  if (flatMenus === null) return '/noAuth';

  const { redirect, path, children } = item;

  if (redirect) {
    const matchMenu = getMatchMenu(redirect, flatMenus, true, true);

    if (matchMenu.length) return redirect;

    if (children && children.length) {
      return getAuthPath(children[0], flatMenus);
    } else {
      return '/noAuth';
    }
  } else {
    return path;
  }
};

export const getMenuMatches = (
  flatMenuKeys: string[] = [],
  path: string,
  exact?: boolean,
): string[] | undefined =>
  flatMenuKeys
    .filter((item) => {
      if (item === '/' && path === '/') {
        return true;
      }
      if (item !== '/' && item !== '/*' && item && !isUrl(item)) {
        const pathKey = stripQueryStringAndHashFromPath(item);

        try {
          // exact
          if (exact) {
            if (pathToRegexp(`${pathKey}`).test(path)) {
              return true;
            }
          } else {
            // /a
            if (pathToRegexp(`${pathKey}`, []).test(path)) {
              return true;
            }
            // /a/b/b
            if (pathToRegexp(`${pathKey}/(.*)`).test(path)) {
              return true;
            }
          }
        } catch (error) {
          // console.log(error, path);
        }
      }
      return false;
    })
    .sort((a, b) => {
      // 如果完全匹配放到最后面
      if (a === path) {
        return 10;
      }
      if (b === path) {
        return -10;
      }
      return a.substr(1).split('/').length - b.substr(1).split('/').length;
    }) as string[];

/**
 * 获取当前的选中菜单列表
 * @param pathname
 * @param menuData
 * @returns MenuDataItem[]
 */
export const getMatchMenu = (
  pathname: string,
  flatMenus: Record<string, MenuDataItem>,
  /**
   * 要不要展示全部的 key
   */
  fullKeys?: boolean,
  exact?: boolean,
): MenuDataItem[] => {
  const flatMenuKeys = Object.keys(flatMenus);

  let menuPathKeys = getMenuMatches(flatMenuKeys, pathname || '/', exact);

  if (!menuPathKeys || menuPathKeys.length < 1) {
    return [];
  }
  if (!fullKeys) {
    menuPathKeys = [menuPathKeys[menuPathKeys.length - 1]];
  }
  return menuPathKeys
    .map((menuPathKey) => {
      const menuItem = flatMenus[menuPathKey] || {
        layout_keys: '',
        key: '',
      };

      // 去重
      const map = new Map();
      const parentItems = (menuItem.layout_keys || [])
        .map((key: string) => {
          if (map.has(key)) {
            return null;
          }
          map.set(key, true);
          return flatMenus[key];
        })
        .filter((item: MenuDataItem | null) => item) as MenuDataItem[];

      return parentItems;
    })
    .flat(1);
};

export default getMatchMenu;
