import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react';
import Cookies from 'js-cookie';
import { Button } from 'antd';
import { Message, Icon, FloatingButton, PriceButton } from '@/components';
import { ProLayout } from '@ant-design/pro-components';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import defaultSettings from '@/const/defaultSettings';
import { AccountStore, CommonStore, PageCacheStore } from '@/stores';
import HeaderMenu from './HeaderMenu';
import SiderMenu from './SiderMenu';
import Breadcrumb from './Breadcrumb';
import AuthContainer from './AuthContainer';
import HeaderLeftContent from './HeaderLeftContent';
import AvatarPopover from './AvatarPopover';
import { getFlatMenus, formartMenu } from '@/tools/getFlatMenus';
import { ApplicationInfo } from '@/const/application';
import getMatchMenu from '@/tools/getMatchMenu';
import { handleHasPreviousLevel, handleSelfJdHasPreviousLevel } from '@/tools/compatibility';

import type { UserInfo } from '@/stores/account';
import type { MenuDataItem } from '@/const/route.config';

import './index.less';

export interface AuthorizedModuleType {
  authorizedCount: number;
  moduleId: string | number;
  moduleName: string;
  status: 0 | 1;
  totalGrantNum: number;
}

const HasToken = Cookies.get('token');
// 纯净模式展示的路由
const pureRoute = new Map([['/login', true]]);

const { systemId, title } = ApplicationInfo;

const BasicLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [menu, setMenu] = useState<MenuDataItem[]>([]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [authModule, setAuthModule] = useState<AuthorizedModuleType[]>(); // 权限模块

  // 扁平化菜单，用于搜索，避免频繁计算
  const [flatMenus, setFlatMenus] = useState<Record<string, MenuDataItem> | null>(null);

  const handleHasToken = async () => {
    await AccountStore.getLiguanjiaToken();

    const { member_token } = await AccountStore.getLiguanjiaToken();
    const { has_previous_level } = await AccountStore.getTeamHistory(member_token);
    const { is_open_self_jd } = await AccountStore.getTeamSelfInfo(member_token);

    CommonStore.updateState({
      memberToken: member_token,
    });

    AccountStore.getMenuPermission({
      systemId,
      resourceTypeList: [1, 2],
    }).then(async (res) => {
      const __menu = formartMenu(res, [title]);
      // 兼容3.0旧版菜单
      handleHasPreviousLevel(__menu, has_previous_level);
      handleSelfJdHasPreviousLevel(__menu, +is_open_self_jd === 1);

      setMenu(__menu);

      const __flatMenus = __menu.length ? getFlatMenus(__menu) : null;
      setFlatMenus(__flatMenus);
    });
    AccountStore.getUserInfo().then((data) => {
      const fenxiaoToken = Cookies.get('fenxiaoToken');
      !fenxiaoToken && AccountStore.getMiaohuoToken({ id: data?.id });

      CommonStore.getCsShowInfo(data?.id); // 咨询飞象客服
      CommonStore.getAuthModule({ employeeId: data?.id }).then((data) => setAuthModule(data));
      setUserInfo(data);
    });
    // 获取站点id
    AccountStore.getSiteId();
    // 获取开通的权限列表
    AccountStore.getOpenRightList();
  };
  useLayoutEffect(() => {
    if (HasToken) {
      handleHasToken();
    } else {
      const loginUrl = `/account/login?redirectUrl=${encodeURIComponent(window.location.href)}`;
      window.location.hostname !== 'localhost' && window.location.replace(loginUrl);
    }
  }, []);

  const [showPageTab, setShowPageTab] = useState<boolean>(false);
  const [matchMenu, setMatchMenu] = useState<MenuDataItem[]>([]);
  const [currentMenu, setCurrentMenu] = useState<MenuDataItem>();
  useEffect(() => {
    // 切换页面时，重置滚动条
    window.scrollTo(0, 0);

    if (flatMenus && location.pathname) {
      const pathname =
        location.pathname === '/redirectPage' ? location.state?.path : location.pathname;

      const _matchMenu = getMatchMenu(pathname, flatMenus, true, true);

      const checkMenu = _matchMenu?.[_matchMenu.length - 1];

      if (!checkMenu) {
        navigate('/noAuth');
        return;
      } 

      CommonStore.updateState({
        currentMenu: checkMenu,
      });
      setMatchMenu(_matchMenu);
      setCurrentMenu({ ...checkMenu });

      const { layout_keys: matchMenuKeys } = checkMenu || {};
      const [key] = matchMenuKeys || [];

      let firstMenuItem: MenuDataItem | undefined;

      if (key) {
        firstMenuItem = flatMenus[key];
      }

      const { showPageTab } = JSON.parse(decodeURIComponent(firstMenuItem?.props || '{}'));
      setShowPageTab(showPageTab);

      if (checkMenu && showPageTab) {
        const singleTab = JSON.parse(decodeURIComponent(checkMenu.props || '{}'))?.singleTab;

        PageCacheStore.updatePageTab({
          key: `${location.pathname}${singleTab ? '' : location.search}`,
          name: checkMenu.name || '标签页',
          singleTab,
        });
      }
    }
  }, [flatMenus, location]);
  useEffect(() => {
    CommonStore.getCsPermissionInfo();
  }, []);
  // 判断用户是否改变
  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        AccountStore.checkUserId().then((data: any) => {
          if (!data) {
            window.location.replace('/module/workbench/dashboard');
          }
        });
      } else {
        // 当前标签页变为不可见状态
        // 执行相应的操作
      }
    });
  }, []);

  const showConsoleBtn = useMemo(
    () =>
      userInfo?.adminFlag === 1 ||
      (flatMenus?.['/group/console'] && flatMenus?.['/group/console']?.children?.length),
    [userInfo, flatMenus],
  );

  return (
    <>
      <ProLayout
        {...defaultSettings}
        siderWidth={180}
        location={location}
        className="peliten__layout"
        style={{ flexDirection: 'row', height: '100vh' }}
        pure={pureRoute.get(location.pathname)} // 是否展示纯净模式
        headerRender={(_, defaultDom) => <div id="fx__layout__header">{defaultDom}</div>}
        headerTitleRender={() => <HeaderLeftContent />}
        headerContentRender={() => (
          <HeaderMenu menuData={menu} matchMenuKeys={currentMenu?.layout_keys} />
        )}
        actionsRender={() =>
          !!showConsoleBtn && (
            <Button
              ghost
              type="primary"
              className="fle-console-btn"
              icon={<Icon type="icon-qiyekongzhitai" />}
              onClick={() => navigate('/module/workbench/account/carpdanceAccount')}
            >
              企业控制台
            </Button>
          )
        }
        avatarProps={{
          src: '//oss.elebuys.com/tmpdir/202308171743390002128752.png',
          title: userInfo?.name,
          style: { background: '#bbcfff' },
          render: (_, dom) => (
            <AvatarPopover userInfo={userInfo} showConsoleBtn={!!showConsoleBtn}>
              <div style={{ whiteSpace: 'nowrap' }}>
                {dom}
                <DownOutlined style={{ fontSize: 12, marginLeft: 10 }} />
              </div>
            </AvatarPopover>
          ),
        }}
        menuRender={() =>
          HasToken &&
          !matchMenu[0]?.hideChildrenInMenu && (
            <SiderMenu
              userInfo={userInfo}
              matchMenu={matchMenu}
              currentMenu={currentMenu}
              matchMenuKeys={currentMenu?.layout_keys}
            />
          )
        }
        footerRender={false}
      >
        <AuthContainer authModule={authModule} matchMenu={matchMenu}>
          <div className="fx__layout-content" style={{ paddingTop: showPageTab ? 36 : 0 }}>
            <Breadcrumb matchMenu={matchMenu} currentMenu={currentMenu} />
            <Outlet
              context={{
                userInfo,
                matchMenu,
                currentMenu,
                flatMenus,
              }}
            />
          </div>
        </AuthContainer>
      </ProLayout>

      <Message />

      {!['/showroom', '/program/mallchat'].some((path) => location.pathname.includes(path)) && (
        <FloatingButton userInfo={userInfo} />
      )}
      <PriceButton />
    </>
  );
};

export default observer(BasicLayout);
