import type { MenuDataItem } from '@/const/route.config';
import { stripQueryStringAndHashFromPath } from './transformRoute';

// 格式化菜单，为每个菜单添加 layout_parentKeys
export const formartMenu = (
  menuData: MenuDataItem[],
  parentNames: string[] = [],
  parentKeys: string[] = [],
): MenuDataItem[] => {
  return menuData.map((item) => {
    const __item = {
      ...item,
      layout_keys: [...parentKeys, item.path || ''],
      layout_names: `${parentNames.join('-')}-${item.name || ''}`,
    };
    if (__item.children?.length) {
      __item.children = formartMenu(
        __item.children,
        [...parentNames, __item.name || ''],
        [...parentKeys, __item.path || ''],
      );
    }

    return __item;
  });
};

/**
 * 获取打平的 menuData
 * 以 path 为 key
 * @param menuData
 */
export const getFlatMenus = (menuData: MenuDataItem[] = []): Record<string, MenuDataItem> => {
  let menus: Record<string, MenuDataItem> = {};
  menuData.forEach((mapItem) => {
    const item = { ...mapItem };
    if (!item || !item.path) {
      return;
    }
    const routerChildren = item.children || [];
    menus[stripQueryStringAndHashFromPath(item.path || '/')] = {
      ...item,
    };
    menus[item.path || '/'] = { ...item };

    if (routerChildren) {
      menus = { ...menus, ...getFlatMenus(routerChildren) };
    }
  });
  return menus;
};

export default getFlatMenus;
