import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RedirectPage: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    state.path && navigate(state.path, { replace: true });
  }, []);

  return null;
};
export default RedirectPage;
