import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Space, Tag } from 'antd';
import { CommonStore } from '@/stores';
import { observer } from 'mobx-react';
import Draggable from 'react-draggable';
import { message } from '../Message';
import './index.less';

// 悬浮按钮组件
const PriceButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalRate, setTotalRate] = useState(0);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const clickThreshold = 5;

  // 点击商品
  const handleItemCheck = (e: any, item: any, index: any) => {
    const value = e.target.checked;
    const selectList = [...selectedRowKeys];
    const modalData = [...data];
    modalData[index].select = value;
    setData(modalData);

    if (!value) {
      // 不勾选，加入数组
      selectList.push(item.id);
      setSelectedRowKeys(selectList);
    } else {
      // 重新勾上，数组去除
      const taget = selectList.indexOf(item.id);
      if (taget != -1) {
        selectList.splice(taget, 1);
        setSelectedRowKeys(selectList);
      }
    }
  };

  const handleDragStop = (_: any, data: any) => {
    const { x, y } = data;

    // 判断点击事件
    if (
      Math.abs(x - dragOffset.x) < clickThreshold &&
      Math.abs(y - dragOffset.y) < clickThreshold
    ) {
      setIsClick(true);
    }

    setDragOffset({ x, y });
  };

  const handleModalShow = () => {
    if (!isClick) return;
    const all = data.map((item: any) => {
      return {
        ...item,
        select: true,
      };
    });
    setData(all);
    setSelectedRowKeys([]);
    setIsModalOpen(true);
  };

  // 全部上架
  const handleModalOk = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('unselected', JSON.stringify(selectedRowKeys));
    const res = await CommonStore.productReplace(CommonStore.memberToken, formData);
    if (res) {
      message.success('上架成功');
      setLoading(false);
      setIsModalOpen(false);
      getList();
    }
  };

  const getList = async () => {
    const data = await CommonStore.getMatchList(CommonStore.memberToken);
    if (data.total > 0) {
      const list = data.list.map((item) => {
        return {
          ...item,
          select: true,
        };
      });
      setData(list);
      setTotal(data.total);
      setTotalRate(data.totalRate);
      init();
    } else {
      setData([]);
      setTotal(0);
      setTotalRate(0);
    }
  };

  const formattedTotalRate = (value) => {
    return Math.round(value);
  };

  const init = async () => {
    const data = await CommonStore.showMatchList(CommonStore.memberToken);
    if (data == 1) {
      setIsModalOpen(true);
    }
  };

  const renderItem = () => {
    return data?.map((item: any, index: any) => {
      return (
        <div className="price-goods-item" key={item.id}>
          <div className="goods-item-left">
            <Checkbox checked={item.select} onChange={(e) => handleItemCheck(e, item, index)} />
            <img className="price-goods-img" src={item.pic} />
            <div>
              <div className="price-goods-title">{item.title}</div>
              <div className="price-goods-type">
                <Space>
                  <span
                    style={{
                      background: '#DC700D',
                      borderRadius: 6,
                      padding: '4px 8px',
                      color: '#ffffff',
                    }}
                  >
                    平台自营惊爆价
                  </span>
                  <span
                    color="#DC700D"
                    style={{
                      borderRadius: 6,
                      background: '#ffffff',
                      padding: '4px 8px',
                      color: '#DC700D',
                      border: '1px solid #DC700D',
                    }}
                  >
                    一件代发
                  </span>
                  <span
                    color="#DC700D"
                    style={{
                      borderRadius: 6,
                      background: '#ffffff',
                      padding: '4px 8px',
                      color: '#DC700D',
                      border: '1px solid #DC700D',
                    }}
                  >
                    包邮
                  </span>
                </Space>
              </div>
              <div className="price-goods-price" style={{ marginTop: 10 }}>
                <div className="goods-price-right">
                  <div style={{ fontSize: '24px', position: 'relative' }}>¥{item.target_price}</div>
                </div>

                <img
                  style={{ width: '20px', height: '20px', margin: '0 20px' }}
                  src="https://oss.elebuys.com/srm/202409261425260009305182.png"
                />
                <div className="goods-price-left">
                  <span>¥{item.source_price}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="price-goods-per">
            <img src="https://oss.elebuys.com/202408151119120005388771.png" />
            <div className="price-goods-per-title">立赚</div>
            <div className="price-goods-per-price">{formattedTotalRate(item.difference)}%</div>
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (CommonStore.memberToken) {
      getList();
    }
  }, [CommonStore.memberToken]);

  return (
    <Draggable
      handle=".price-button-container"
      grid={[1, 1]} // 可选的网格设置
      scale={1} // 可选的缩放设置
      onStart={() => {
        setIsClick(false);
      }} // 拖动开始时执行的函数
      onStop={handleDragStop}
    >
      <div className="price-button-container">
        {/* 悬浮按钮 */}
        {data.length > 0 && (
          <img
            draggable={false}
            className="price-button"
            src="https://oss.elebuys.com/202408151421250005506664.png"
            onClick={handleModalShow}
          />
        )}
        <Modal
          width={800}
          title={
            <img
              src="https://oss.elebuys.com/srm/202409261418190009290297.png"
              style={{ width: 93, height: 22 }}
            />
          }
          open={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onOk={handleModalOk}
          className="fle-modal-content2"
          footer={() => {
            if (data.length == 0) {
              return null;
            }
            return (
              <div className="price-modal-btn">
                <div className="price-modal-btn-left">
                  注：原先已挑选的商品按照原分销价和销售价展示，未挑选的商品分销价和销售价默认等于市场价；
                </div>
                <img
                  className="price-modal-btn-right"
                  src="https://oss.elebuys.com/202408151052570005387810.png"
                  onClick={handleModalOk}
                />
              </div>
            );
          }}
          closeIcon={
            <img
              className="price-modal-close-img"
              src="https://oss.elebuys.com/202408151058520005388043.png"
              alt="Close"
            />
          }
        >
          <div className="price-modal-container">
            {data.length == 0 && (
              <div className="no-data-wrap">
                <img
                  className="no-data-img"
                  src="https://oss.elebuys.com/202408151816120005679967.png"
                  alt=""
                />
                <div>暂无可推荐商品</div>
              </div>
            )}
            {data.length > 0 && (
              <>
                <div className="price-modal-header">
                  <img
                    className="price-modal-header-img"
                    src="https://oss.elebuys.com/202408151027020005367517.png"
                    alt=""
                  />
                  <div className="goods-num">{total}</div>
                  <div className="goods-per">{formattedTotalRate(totalRate)}%</div>
                </div>
                <div className="price-modal-main">{renderItem()}</div>
              </>
            )}
          </div>
        </Modal>
      </div>
    </Draggable>
  );
};

export default observer(PriceButton);
