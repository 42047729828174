import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Popover } from 'antd';
import { useThrottleFn } from 'ahooks';

import { Icon } from '@/components';
import PageTab from './pageTab';
import { PageCacheStore } from '@/stores';
import { ApplicationInfo } from '@/const/application';
import { useTabNavigate } from '@/hooks/useTabNavigate';

import './index.less';

const { dashboard } = ApplicationInfo;

interface IPageTabsProps {
  collapsed?: boolean;
}

function PageTabs({ collapsed = true }: IPageTabsProps) {
  const location = useLocation();
  const navigate = useTabNavigate();

  const { homePage, alivePages } = PageCacheStore;

  const wrapperElRef = useRef<HTMLDivElement>(null);
  const navElRef = useRef<HTMLDivElement>(null);

  const onResize = () => {
    const wrapperEl = wrapperElRef.current;
    const navEl = navElRef.current;

    if (!wrapperEl || !navEl) {
      return;
    }

    const index = PageCacheStore.currentTabIndex;

    if (index === -1) {
      return;
    }

    const tabEls = [...navEl.querySelectorAll('.page-tab')] as HTMLDivElement[];

    let left = 0;
    for (let i = 0; i < index; i++) {
      left += tabEls[i].clientWidth;
    }

    wrapperEl.scrollTo({ left, top: 0 });
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    onResize();
  }, [alivePages]);

  // 滑动 tabs
  const scrollBy = (order: 1 | -1) => {
    const wrapperEl = wrapperElRef.current;
    const navEl = navElRef.current;

    if (!wrapperEl || !navEl) {
      return;
    }

    const tabEls = [...navEl.querySelectorAll('.page-tab')] as HTMLDivElement[];

    const { scrollLeft, clientWidth: wrapperElWidth } = wrapperEl;

    // 获取分页
    const pages: number[] = [0];
    let width = 0;
    for (let i = 0; i < tabEls.length; i++) {
      const { clientWidth } = tabEls[i];
      if (width >= wrapperElWidth * pages.length) {
        pages.push(width - clientWidth);
      }
      width += clientWidth;
    }
    pages.push(width);

    // 前后翻页
    wrapperEl.scrollTo({
      left:
        (order > 0
          ? pages.find((left) => left > scrollLeft)
          : pages.reverse().find((left) => left < scrollLeft)) || 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  const { run: refreshPage } = useThrottleFn(
    () => {
      PageCacheStore.refreshPage(`${location.pathname}${location.search}`).then((path) => {
        path && navigate('/redirectPage', { state: { path }, replace: true });
      });
    },
    { wait: 300 },
  );

  const { run: closePageTab } = useThrottleFn(
    () => {
      PageCacheStore.closePageTab(`${location.pathname}${location.search}`).then((path) => {
        path && navigate('/redirectPage', { state: { path }, replace: true });
      });
    },
    { wait: 300 },
  );

  const { run: closeAllTabs } = useThrottleFn(
    () => {
      PageCacheStore.closeAllTabs();
      navigate('/module/workbench/dashboard');
    },
    { wait: 300 },
  );

  // 右侧操作项
  const actions = [
    {
      icon: 'icon-zuohua',
      onClick: () => scrollBy(-1),
    },
    {
      icon: 'icon-youhua',
      onClick: () => scrollBy(1),
    },
    {
      icon: 'icon-gengduocaozuo',
      children: [
        {
          title: '刷新当前页面',
          icon: 'icon-shuaxindangqianyemian',
          onClick: refreshPage,
        },
        {
          title: '关闭当前页面',
          icon: 'icon-guanbidangqianyemian',
          onClick: closePageTab,
          hidden: `${location.pathname}${location.search}`.indexOf(dashboard) === 0,
        },
        {
          title: '关闭所有页面',
          icon: 'icon-guanbisuoyouyemian',
          onClick: closeAllTabs,
        },
      ],
    },
  ];

  const style = collapsed
    ? { left: 64, width: 'calc(100% - 64px)' }
    : { left: 180, width: 'calc(100% - 180px)' };

  return (
    <>
      <div className="fx__layout-page-tabs-space" />
      <div className="fx__layout-page-tabs" style={style}>
        <PageTab
          item={homePage}
          iconRender={() => <Icon type="icon-gongzuotai" />}
          key="home-page"
        />
        <div
          ref={wrapperElRef}
          className="page-tabs-nav-wrapper"
          onWheel={(e) => {
            wrapperElRef.current?.scrollBy(e.deltaY, 0);
          }}
        >
          <div
            ref={navElRef}
            className="page-tabs-nav"
            onMouseOver={() => {
              document.body.style.overscrollBehaviorX = 'none';
            }}
            onMouseOut={() => {
              document.body.style.overscrollBehaviorX = 'auto';
            }}
          >
            {alivePages.map((item) => (
              <PageTab
                item={item}
                onDelete={() => {
                  PageCacheStore.closePageTab(item.key, item.singleTab).then((path) => {
                    path && navigate(path, { replace: true });
                  });
                }}
                key={item.key}
              />
            ))}
          </div>
        </div>
        <div className="page-tabs-actions">
          {actions.map((action) => {
            if (action.children?.length) {
              return (
                <Popover
                  rootClassName="page-tabs-action-more"
                  placement="bottomRight"
                  arrow={false}
                  content={
                    <div className="action-more">
                      {action.children
                        .filter((item) => !item.hidden)
                        .map((item) => (
                          <div className="action" onClick={item.onClick} key={item.icon}>
                            <Icon type={item.icon} />
                            {item.title}
                          </div>
                        ))}
                    </div>
                  }
                  key={action.icon}
                >
                  <div className="page-tabs-action">
                    <Icon type={action.icon} />
                  </div>
                </Popover>
              );
            }

            return (
              <div className="page-tabs-action" onClick={action.onClick} key={action.icon}>
                <Icon type={action.icon} />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default observer(PageTabs);
