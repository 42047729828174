import React from 'react';

import './index.less';

const HeaderLeftContent: React.FC = () => {
  return (
    <>
      <div className="fle-layout-header-left-content">
        <div className="fle-layout-header-logo">
          <img src="//oss.elebuys.com/logo/carpunion/text-logo.png" alt="logo" />
        </div>
      </div>
    </>
  );
};
export default React.memo(HeaderLeftContent);
